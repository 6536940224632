<template>
  <div>
    <!--
      ---
      ---
      ---- PAYMENT METHODS MODAL
      ---
      ---
      -->
    <v-dialog
      v-model="modalAddPayment"
      persistent
      max-width="750px"
    >
      <v-card min-height="300px" :disabled="disabledCard">
        <v-card-title class="headline">
          <v-spacer></v-spacer>
          {{ $t('AddCard') }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :ripple="false"
            @click="modalAddPayment = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <StripeSavePayment
            v-if="$stripeConfig.pk"
            :api-key="$stripeConfig.pk"
            :brand-i-d="$route.params.brandUUID"
            ref="stripeSavePayment"
            @save="saveCard"
            @errorSave="errorSave"
            :key="addPaymentKey"
          >
          </StripeSavePayment>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card flat class="px-5 ml-sm-3 mt-10">
      <v-alert
        text
        outlined
        type="info"
        v-if="brandUserRolesAdminAccessErrorMessage"
        class="mt-4 col-12 text-center"
      >
        {{ brandUserRolesAdminAccessErrorMessage }}
      </v-alert>
      <v-card-title>
        {{ $t('PaymentMethods') }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="brandUserRoles && brandUserRoles.includes('admin')"
          @click="modalAddPayment = true"
          color="primary"
          class="text-none"
        >
          <v-icon small class="mr-2">
            fas fa-plus-circle
          </v-icon>
          {{ $t('AddCard') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-if="paymentMethods"
          :loading="loadingPayments"
          :headers="headersPayments"
          :items="paymentMethods"
          :footer-props="{
            itemsPerPageOptions: [15, 50, 100, -1],
          }"
        >
          <template v-slot:[`item.payments`]="{ item }">
            <template v-if="item.type === 'card'">
              <v-icon v-if="item.card.brand === 'visa'">
                fab fa-cc-visa
              </v-icon>
              <v-icon
                v-else-if="item.card.brand === 'mastercard'"
              >
                fab fa-cc-mastercard
              </v-icon>
              <v-icon
                v-else-if="item.card.brand === 'amex'"
              >
                fab fa-cc-amex
              </v-icon>
              <v-icon v-else>
                far fa-credit-card
              </v-icon>
              <span class="ml-2"> ******** {{ item.card.last4 }} </span>
              <span class="ml-5">
                {{ item.card.exp_month }} / {{ item.card.exp_year }}
              </span>
              <span class="ml-5">
                {{ item.billing_details.name }}
              </span>
              <v-chip
                v-if="item.id === paymentMethodDefault"
                class="ml-5"
                color="success"
              >
                <v-icon small class="mr-2">
                  fas fa-check
                </v-icon>{{ $t('Default') }}
              </v-chip>
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu
              offset-y
              close-on-click
              close-on-content-click
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  v-on="on"
                >
                  <v-icon>fas fa-ellipsis-v</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  :disabled="item.id === paymentMethodDefault"
                  @click="setDefault(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon
                      :disabled="item.id === paymentMethodDefault"
                      small
                    >
                      fas fa-check
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ $t('SetDefault') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="item.id === paymentMethodDefault"
                  @click="deletePayment(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon
                      :disabled="item.id === paymentMethodDefault"
                      small
                    >
                      fas fa-trash
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ $t('Delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
      <BankTransferInstructions></BankTransferInstructions>
    </v-card>
  </div>
</template>

<script>
import {
  EventBus,
  ApiErrorParser,
  StripeSavePayment
} from '@cloudmanufacturingtechnologies/portal-components';

import BankTransferInstructions from '../../../components/bankTransferInstructions/BankTransferInstructions.vue';

const i18nData = require('./pageAdministrationPaymentMethods.i18n.json');

export default {
  name: 'PageAdministrationPaymentMethods',
  components: { StripeSavePayment, BankTransferInstructions },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brandUserRolesErrorMessage: null,
      loading: false,
      disabled: true,
      brandUserRoles: null,
      brandUserRolesAdminAccessErrorMessage: '',
      loadingPayments: true,
      modalAddPayment: false,
      paymentMethods: [],
      paymentMethodDefault: null,
      headersPayments: null,
      addPaymentKey: `payment-${Date.now()}`,
      disabledCard: false,
    };
  },
  created() {
    if (!this.$appConfig.brand) {
      EventBus.$emit('reloadBrand');
    }
    this.headersPayments = [
      { text: this.$t('Payments'), value: 'payments', sortable: false },
      { text: this.$t('Actions'), value: 'actions', sortable: false },
    ];
    /**
     * GET BRAND USER ROLES
     */
    this.getBrandUserRoles();

    this.getSavedPaymentMethods();
  },
  methods: {
    /**
     * GET BRAND USER ROLES
     */
    getBrandUserRoles() {
      this.$apiInstance
        .getBrandUserRoles(
          this.$appConfig.currentBrand,
          this.$appConfig.user._id
        )
        .then(
          (brandUserRolesData) => {
            this.brandUserRoles = brandUserRolesData;
            if (this.brandUserRoles.includes('admin')) {
              this.disabled = false;
            } else {
              this.brandUserRolesAdminAccessErrorMessage = this.$t(
                'needToBeAdmin'
              );
            }
          },
          (error) => {
            this.brandUserRolesErrorMessage = ApiErrorParser.parse(error);
          }
        );
    },
    /**
     * GET SAVED PAYMENT METHODS
     */
    getSavedPaymentMethods() {
      this.paymentMethods = [];
      this.loadingPayments = true;
      this.$apiInstance
        .getBrandDefaultPaymentMethod(this.$appConfig.currentBrand)
        .then(
          (paymentMethodDefaultData) => {
            this.paymentMethodDefault = paymentMethodDefaultData;
            this.$apiInstance
              .getBrandPaymentMethods(this.$appConfig.currentBrand)
              .then(
                (paymentMethodsData) => {
                  this.paymentMethods = paymentMethodsData;
                },
                (error) => {
                  /**
                   * Component BeelseNotifications used
                   */
                  this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
                }
              )
              .finally(() => {
                this.loadingPayments = false;
                EventBus.$on('cardAdded', () => {
                  this.modalAddPayment = false;
                });
              });
          },
          (error) => {
            ApiErrorParser.parse(error);
            this.loadingPayments = false;
          }
        );
    },
    /**
     * DELETE PAYMENT
     */
    deletePayment(id) {
      this.loadingPayments = true;
      this.paymentMethods = [];
      this.$apiInstance
        .deleteBrandPaymentMethod(this.$appConfig.currentBrand, id)
        .then(
          () => {
            //this.getSavedPaymentMethods();
            //EventBus.$emit('reloadBrand');
            //Reload page
            this.$router.go();
          },
          (error) => {
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loadingPayments = false;
        });
    },
    setDefault(id) {
      this.loadingPayments = true;
      const modifyBrandDefaultPaymentMethodBody = new this.$BcmModel.ModifyBrandDefaultPaymentMethodBody(); // ModifyBrandDefaultPaymentMethodBody | The default Payment method to use
      modifyBrandDefaultPaymentMethodBody.sourceUUID = id;
      this.$apiInstance
        .modifyBrandDefaultPaymentMethod(
          this.$appConfig.currentBrand,
          modifyBrandDefaultPaymentMethodBody
        )
        .then(
          () => {
            this.getSavedPaymentMethods();
          },
          (error) => {
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loadingPayments = false;
        });
    },
    saveCard() {
      EventBus.$on('requestForAddCard', () => {
        this.disabledCard = true;
      });
      //this.getSavedPaymentMethods();
      //EventBus.$emit('reloadBrand');
      //Reload page
      this.$router.go();
      this.$refs.stripeSavePayment.loading = false;
    },
    errorSave() {
      this.getSavedPaymentMethods();
      this.$refs.stripeSavePayment.loading = false;
      this.modalAddPayment = false;
    },
  },
};
</script>
